/* global moment */

angular.module(config.app.name).component('questions', {
    templateUrl: 'components/procedure/components/questions.component.html',
    bindings: {
        buyer: '<',
        vehicle: '<',
        procedureId: '<',
        procedureType: '<',
        canGoBack: '<',
        getData: '&',
        setData: '&'
    },
    controller: function ($element, $scope, $timeout, sessionStorageService) {

        var self = this;
        
        self.view = {
            title: 'Questions',
            icon: 'help'
        };        

        self.completed = false;
        self.questionsNb = 0;
        self.currentQuestion = 0;
        self.slideCanGoNext = false;
        self.slideCanGoPrevious = false;

        self.$onInit = function () {

            self.questions = getProcedureQuestions(self.procedureType);
            self.questions.forEach((question) => {
                if (self.buyer.type === PERSONNE_MORALE) {
                    if (question.availableForPro !== true) {
                        if (question.modelName === 'buyerIsHosted') {
                            self.__set('buyerIsHosted', false);
                        }
                        question.hidden = true;
                    }
                }
                if (self.vehicle && Array.isArray(question.notAvailableForVehicles)) {
                    if (question.notAvailableForVehicles.includes(self.vehicle.typeId)) {
                        question.hidden = true;
                    }
                }
                let k = question.modelName;
                let v = sessionStorageService.get(k);
                self.setQuestionValue(question, v, true);
                $timeout(() => $scope.$apply());
            });

            self.setQuestionNb();

            self.slideCanGoNext = self.questions[self.currentQuestion].selectedValue !== null;
            self.completed = self.isCompleted();
            $timeout(() => $scope.$apply());
        };

        self.isVisible = function (item) {
            return !item.hidden;
        };

        self.setQuestionNb = function () {
            self.questionsNb = self.questions.filter((question) => question.hidden !== true).length;
            $timeout(() => $scope.$apply());
        };

        self.isCompleted = function () {
            let completed = self.questions.every(question => {
                if (!question.hidden) {
                    return question.selectedValue !== null;
                }
                return true;
            });
            console.info('>>> completed :', completed);
            return completed;
        };

        self.__set = function (key, value, localOnly = false) {
            self[key] = value;
            if (localOnly === false) {
                self.setData({
                    type: 'question',
                    data: {
                        key: key,
                        value: value
                    }
                });
            }//
        };

        self.setQuestionValue = function (question, value, init) {

            if (value instanceof Date) {
                value = moment(value).format('DD/MM/YYYY');
            }
            self.slideCanGoNext = true;
            question.selectedValue = value;

            if (question.actions) {
                ['show', 'hide'].forEach((actionType) => {
                    if (Array.isArray(question.actions[actionType])) {
                        question.actions[actionType].forEach((action) => {
                            let and = true;
                            if (action.and) {
                                and = action.and.values.includes(self[action.and.model]);
                            }
                            if (action.values.includes(value) && and) {
                                action.targets.forEach((target) => {
                                    let question = getQuestion(target);
                                    if (question) {
                                        question.hidden = actionType !== 'show';
                                    }
                                });
                            }
                        });
                    }
                });
                self.setQuestionNb();
            }

            self.__set(question.modelName, value, init);
            if (!init) {
                $timeout(() => {
                    self.slide();
                }, 500);
                self.completed = self.isCompleted();
            }
            $timeout(() => $scope.$apply());
        };

        function getQuestion(modelName = null) {
            if (modelName === null) {
                return;
            }
            return self.questions.find(q => q.modelName === modelName);
        }

        self.slide = function (next = true) {
            if (next === true) {
                if (self.currentQuestion < self.questionsNb - 1) {
                    self.currentQuestion++;
                }
            } else {
                if (self.currentQuestion > 0) {
                    self.currentQuestion--;
                }
            }//            
            self.slideCanGoNext = self.questions[self.currentQuestion].selectedValue !== null;
            self.slideCanGoPrevious = self.currentQuestion > 0;
        };

        function slideNext() {
            if (self.currentQuestion < self.questionsNb + 1) {
                self.currentQuestion++;
            }
            self.slideCanGoPrevious = self.currentQuestion > 1;
        }
        self.slidePrev = function () {
            if (self.currentQuestion > 1) {
                self.slideCanGoNext = true;
                self.currentQuestion--;
            }
            self.slideCanGoPrevious = self.currentQuestion > 1;
        };

        self.next = function () {
            let data = {};
            self.questions.forEach((q) => {
                data[q.modelName] = q.selectedValue;
            });
            self.setData({
                type: 'questions',
                data: {
                    questions: data
                }
            });
        };

        self.back = function () {
            $element[0].dispatchEvent(new CustomEvent('navigation', {
                detail: 'back'
            }));
        };

    }
});